import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "crazyluck",
  casinoId: 86,
  GA_TRACKING:'G-BR5LSY15VC',

  rivalChatGroupName: "Crazy Luck",
  prettyName: "Crazy Luck Casino",
  contactEmail: "support@crazyluck.com",
  docsEmail: "documents@crazyluck.email",

  //SEO
  metaDescription: "Live Casino online with new games Crazy Luck Casino. Play Slots, live dealers, blackjack with no deposit bonus and free spins. Big deposit bonuses at the table, fast and safe banking, accepting Bitcoin, loyalty program, secure mobile payments. Fair gaming (iGaming) and real cash prizes!",
  logoName: "crazy-luck-casino-online-casino-logo.png",
  logoAltText: "Crazy Luck Casino online logo with edgy red and white text popular for exciting slots games and unique casino bonuses."
};


